import { Col, Text } from "@gada-saas/web-ui";
import * as React from "react";

const BlacklistedModalContent: React.VFC = () => {
  return (
    <Col spacing={24}>
      <Text>
        Selama periode percobaan, hanya nomor yang sudah terdaftar yang bisa
        mengakses GudangAda Solusi.
      </Text>

      <Text>
        Silakan hubungi pemilik toko atau sales GudangAda untuk informasi lebih
        lanjut.
      </Text>
    </Col>
  );
};

export default BlacklistedModalContent;
