import * as React from "react";
import { getIsLoggedIn } from "@gada-saas/web-core";
import { useRouter } from "next/router";
import { PAGES } from "@common/constants";
import { LinearProgress } from "@material-ui/core";
import { useOfflineInitialDataFetcher } from "@miscellaneous/offline/components/OfflineInitialDataFetcher/useOfflineIniitalDataFetcher";
import hoistNonReactStatics from "hoist-non-react-statics";
import { useWindowFocus } from "@common/hooks";
import {
  useCachedUserInfoActions,
  useLazyGetUserInfoQuery,
} from "@gada-saas/web-core/user";
import { usePageNameTrackingDataContext } from "@miscellaneous/tracking/contexts/PageNameTrackingContext";
import { useTour } from "@reactour/tour";

export function withLoginRedirection<P extends object>(
  Component: React.ComponentType<P>
): React.VFC<P> {
  const EnhancedPage = (props: P) => {
    const router = useRouter();
    const [isTokenVerified, setIsTokenVerified] = React.useState(false);
    const { fetchAllInitialData } = useOfflineInitialDataFetcher();
    const isWindowFocused = useWindowFocus();
    const CachedUserInfoActions = useCachedUserInfoActions();
    const [lazyGetUserInfoQuery] = useLazyGetUserInfoQuery();
    const { setPageName } = usePageNameTrackingDataContext();
    const { isOpen, setIsOpen } = useTour();

    const redirectToHome = React.useCallback(() => {
      CachedUserInfoActions.resetErrorState();
      lazyGetUserInfoQuery()
        .unwrap()
        .then(() => {
          fetchAllInitialData();
          router.push(PAGES.HOME);
        })
        .catch(() => {});
    }, [
      CachedUserInfoActions,
      fetchAllInitialData,
      lazyGetUserInfoQuery,
      router,
    ]);

    React.useEffect(() => {
      if (isOpen) {
        setIsOpen(false);
      }
    }, [isOpen, setIsOpen]);

    // Set pageName accordingly (for tracking)
    React.useEffect(() => {
      const pathName = window.location.pathname;
      if (pathName.startsWith(PAGES.LOGIN)) {
        setPageName("Login Page");
      } else if (pathName.startsWith(PAGES.REGISTRATION)) {
        setPageName("Registration Page");
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Detect only if window is focused
    React.useEffect(() => {
      if (isWindowFocused) {
        if (getIsLoggedIn()) {
          redirectToHome();
        }
      }
    }, [redirectToHome, isWindowFocused]);

    // Detect if we have token,
    React.useEffect(() => {
      if (getIsLoggedIn()) {
        redirectToHome();
      } else {
        setIsTokenVerified(true);
      }
    }, [redirectToHome]);

    if (!isTokenVerified) {
      return <LinearProgress />;
    }

    return <Component {...props} />;
  };

  return hoistNonReactStatics(EnhancedPage, Component);
}
