import { AppTheme, pxToRem } from "@gada-saas/web-ui";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: AppTheme) => ({
  authLayoutContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    [theme.breakpoints.up(1130)]: {
      gridTemplateRows: "100vh",
    },
    [theme.breakpoints.down(1130)]: {
      "& > div": {
        paddingLeft: pxToRem(24),
        paddingRight: pxToRem(24),
        paddingBottom: pxToRem(24),
      },
      gridTemplateColumns: "1fr",
    },
    [theme.breakpoints.down(500)]: {
      "& > *": {
        maxWidth: "100vw",
      },
    },
  },
  rightPaneContainer: {
    backgroundColor: theme.palette.interface.white,
  },
}));

export default useStyles;
