import { useContext } from "react";
import type { VFC } from "react";
import { Box } from "@material-ui/core";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import useStyles from "./styles";
import { AuthLayoutProps } from "./types";
import AuthCarousel from "@auth/components/AuthCarousel/AuthCarousel";
import { FeaturesFlagContext } from "@common/context/FeaturesFlagProvider/FeaturesFlagProvider";
import AuthMarketingImage from "@auth/components/AuthMarketingImage";

const AuthLayout: VFC<AuthLayoutProps> = ({ children }) => {
  const classes = useStyles();

  const { authLayout } = useContext(FeaturesFlagContext);

  return (
    <>
      <Box className={classes.authLayoutContainer}>
        {authLayout.showCarousel ? <AuthCarousel /> : <AuthMarketingImage />}
        <Box className={classes.rightPaneContainer}>{children}</Box>
      </Box>
    </>
  );
};

export default AuthLayout;
