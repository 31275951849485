import { pxToRem } from "@gada-saas/web-ui";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  container: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: pxToRem(50),
    maxWidth: pxToRem(185),
  },
});

export default useStyles;
