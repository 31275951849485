import { useStyles } from "./styles";
import { YoutubeIcon } from "@common/icons";
import { Text } from "@gada-saas/web-ui";
import { Box, Backdrop } from "@material-ui/core";
import { useState, useRef } from "react";
import Youtube, { YouTubeEvent } from "react-youtube";

const GADA_SOLUSI_YOUTUBE_ID = "ExpZkgS3ZyQ";

const AuthMarketingImage = () => {
  const classes = useStyles();
  const [showYoutubePlayer, setShowYoutubePlayer] = useState(false);
  const player = useRef<YT.Player | null>(null);

  const onClickBackdrop = () => {
    player.current?.pauseVideo();
    setShowYoutubePlayer(false);
  };

  const onReady = (e: YouTubeEvent) => {
    player.current = e.target;
  };

  const onClickOpenVideo = () => {
    player.current?.playVideo();
    setShowYoutubePlayer(true);
  };

  return (
    <Box className={classes.container}>
      <Text variant="h1" className={classes.h1}>
        Kasir Online untuk Tokomu
        <span>gratis*</span>
      </Text>
      <Text variant="h4" className={classes.h4}>
        Lebih dari 18.000 Toko Kelontong dan Grosir telah
        <br /> menggunakan GudangAda Solusi
      </Text>

      <img
        src="/images/WEB_SOLUSI2.png"
        alt="Web Solusi"
        className={classes.image}
      />
      <div className={classes.flexCenterContentItems}>
        <div
          className={classes.footerTextYoutubePlayer}
          onClick={onClickOpenVideo}
        >
          <YoutubeIcon />
          <Text variant="buttonSmall">Cek Video Tutorial</Text>
        </div>
      </div>

      <Backdrop
        className={classes.backdrop}
        open={showYoutubePlayer}
        onClick={onClickBackdrop}
      >
        <div id="gadaSolusiYoutubePlayer">
          <Youtube videoId={GADA_SOLUSI_YOUTUBE_ID} onReady={onReady} />
        </div>
      </Backdrop>
    </Box>
  );
};

export default AuthMarketingImage;
