import {
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup as GRadioGroup,
} from "@material-ui/core";
import clsx from "clsx";
import * as React from "react";
import { Col } from "../../Layout";
import Text from "../../Typography/Typography";
import useStyles from "./styles";
import { RadioGroupProps } from "./types";

const RadioGroup: React.VFC<RadioGroupProps> = ({
  name,
  value,
  handleChange,
  className = "",
  withDivider = false,
  row = false,
  options = [],
  controlClassName,
  radioProps,
  id,
}) => {
  const classes = useStyles();

  const displayRadioLabel = (label: string | React.ReactElement) => {
    if (typeof label === "string") {
      const isActive = value.toLowerCase() === label.toLowerCase();

      return <Text variant={isActive ? "h4" : "body1"}>{label}</Text>;
    } else {
      return label;
    }
  };

  return (
    <GRadioGroup
      id={id}
      row={row}
      className={className}
      name={name}
      value={value}
      onChange={handleChange}
    >
      {options.map((option, i) => {
        return (
          <Col key={option.value}>
            <FormControlLabel
              value={option.value}
              label={displayRadioLabel(option.label)}
              control={<Radio id={option.id} {...radioProps} />}
              className={clsx(classes.flex, controlClassName)}
              disabled={option.disabled}
            />

            {withDivider && i !== options.length - 1 && <Divider />}
          </Col>
        );
      })}
    </GRadioGroup>
  );
};

export default RadioGroup;
