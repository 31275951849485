import { pxToRem } from "@gada-saas/web-ui";
import { AppTheme } from "@gada-saas/web-ui/theme";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: AppTheme) => ({
  carousel: {
    backgroundColor: theme.palette.interface.neutral.light,
    "& > .carousel-slider": {
      height: "100%",
      display: "flex",
      "& > .control-dots": {
        bottom: "10px",
        [theme.breakpoints.down(1024)]: {
          display: "none",
        },
      },
    },
  },
  container: {
    height: "100vh",
    flexWrap: "wrap",
  },
  left: {
    backgroundColor: theme.palette.interface.neutral.light,
    flex: 1,
    display: "flex",
    justifyContent: "center",
    maxWidth: "50%",
  },
  right: {
    backgroundColor: theme.palette.interface.white,
  },
  content: {
    position: "relative",
    padding: `${pxToRem(80)} ${pxToRem(104)}`,
  },
  imageWrapper: {
    [theme.breakpoints.up(768)]: {
      maxWidth: pxToRem(438),
    },
    [theme.breakpoints.down(767)]: {
      maxWidth: pxToRem(300),
    },
    marginLeft: "auto",
    marginRight: "auto",
  },
  indicator: {
    background: theme.palette.interface.neutral.secondary,
    width: pxToRem(8),
    height: pxToRem(8),
    borderRadius: pxToRem(8),
    display: "inline-block",
    "&:hover": {
      cursor: "pointer",
    },
  },
  selectedIndicator: {
    background: theme.palette.interface.teal.primary,
    width: pxToRem(24),
    height: pxToRem(8),
    borderRadius: pxToRem(8),
    marginLeft: pxToRem(-10),
    display: "inline-block",
  },
  arrowStyleRight: {
    background: theme.palette.interface.teal.light,
    right: pxToRem(15),
    position: "absolute",
    zIndex: 2,
    top: "calc(50% - 15px)",
    width: pxToRem(48),
    height: pxToRem(48),
    cursor: "pointer",
  },
  arrowStyleLeft: {
    background: theme.palette.interface.teal.light,
    position: "absolute",
    left: pxToRem(15),
    zIndex: 2,
    top: "calc(50% - 15px)",
    width: pxToRem(48),
    height: pxToRem(48),
    cursor: "pointer",
  },
  arrowIconStyle: {
    color: theme.palette.interface.teal.primary,
  },
  authLayoutContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    [theme.breakpoints.up(1024)]: {
      gridTemplateRows: "100vh",
    },
    [theme.breakpoints.down(1023)]: {
      gridTemplateColumns: "1fr",
    },
    [theme.breakpoints.down(500)]: {
      "& > *": {
        maxWidth: "100vw",
      },
    },
  },
}));
