import { GadaSaasLogo } from "@common/icons";
import { theme } from "@gada-saas/web-ui";
import clsx from "clsx";
import * as React from "react";
import useStyles from "./styles";
import { LogoProps } from "./types";

const Logo: React.VFC<LogoProps> = ({ containerClassName, containerStyle }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.container, containerClassName)}
      style={containerStyle}
    >
      <GadaSaasLogo
        width="100%"
        height="100%"
        fill={theme.palette.interface.teal.primary}
      />
    </div>
  );
};

export default Logo;
