import { pxToRem } from "@gada-saas/web-ui";
import { Box, styled } from "@material-ui/core";

export const StyledAuthFormWrapper = styled(Box)({
  maxWidth: pxToRem(512),
  marginLeft: "auto",
  marginRight: "auto",
  marginTop: pxToRem(76),
  marginBottom: pxToRem(32),
});
