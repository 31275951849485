import { pxToRem } from "@gada-saas/web-ui";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(({ breakpoints, palette, zIndex }) => ({
  container: {
    backgroundColor: palette.interface.neutral.light,
    paddingTop: `${pxToRem(80)}`,
    position: "relative",
    height: "100%",
    "& > div": {
      width: "100%",
    },
  },
  h1: {
    position: "relative",
    textAlign: "center",
    marginBottom: pxToRem(6),
    "& > span": {
      width: pxToRem(67),
      position: "absolute",
      textTransform: "uppercase",
      backgroundColor: "#03A199",
      color: palette.interface.white,
      padding: `${pxToRem(4)} ${pxToRem(8)}`,
      borderRadius: pxToRem(5),
      marginLeft: pxToRem(6),
      fontFamily: "Inter",
      fontWeight: "600",
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "initial",
      top: 0,
      [breakpoints.down(560)]: {
        maxWidth: pxToRem(300),
        top: "-20px",
        right: "10px",
      },
    },
  },
  h4: {
    textAlign: "center",
    fontFamily: "Inter",
    fontWeight: 400,
  },
  footerTextYoutubePlayer: {
    cursor: "pointer",
    textDecoration: "none",
    "& > *:nth-child(2)": {
      marginLeft: pxToRem(8),
    },
    "& > *": {
      verticalAlign: "middle",
    },
  },
  image: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    [breakpoints.down(1536)]: {
      aspectRatio: "16/9",
    },
    objectFit: "contain",
    aspectRatio: "4/3",
    maxInlineSize: "100%",
  },
  backdrop: {
    zIndex: zIndex.drawer,
  },
  flexCenterContentItems: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
