import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  flex: {
    display: "flex",
    "& > *:last-child": {
      flex: "1 1 0",
    },
  },
});

export default useStyles;
