import { useState } from "react";
import { Col, pxToRem, Text } from "@gada-saas/web-ui";
import { Box, IconButton } from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import clsx from "clsx";
import Image from "next/image";
import { Carousel } from "react-responsive-carousel";
import { useStyles } from "./styles";

const AuthCarousel = () => {
  const classes = useStyles();
  const [activeIndex, setActiveIndex] = useState<number>(0);

  return (
    <Carousel
      className={classes.carousel}
      showThumbs={false}
      showStatus={false}
      infiniteLoop
      autoPlay
      renderArrowPrev={(onClickHandler) =>
        activeIndex !== 0 && (
          <IconButton
            id="button_auth_carousel_left"
            type="button"
            onClick={onClickHandler}
            className={classes.arrowStyleLeft}
          >
            <ChevronLeft className={classes.arrowIconStyle} />
          </IconButton>
        )
      }
      renderArrowNext={(onClickHandler) =>
        activeIndex !== 2 && (
          <IconButton
            id="button_auth_carousel_right"
            type="button"
            onClick={onClickHandler}
            className={classes.arrowStyleRight}
          >
            <ChevronRight className={classes.arrowIconStyle} />
          </IconButton>
        )
      }
      renderIndicator={(onClickHandler, isSelected, index) => {
        if (isSelected) {
          setActiveIndex(index);
        }
        return (
          <Col
            id="div_indicator_auth"
            className={clsx({
              [classes.indicator]: !isSelected,
              [classes.selectedIndicator]: isSelected,
            })}
            onClick={!isSelected ? onClickHandler : () => {}}
            style={{
              marginLeft: pxToRem(20),
            }}
          />
        );
      }}
    >
      <Box style={{ height: "100%" }}>
        <div className={classes.imageWrapper}>
          <Image
            src="/images/onboarding/carousel-1.png"
            width="100%"
            height="100%"
            layout="responsive"
            objectFit="contain"
            alt="image-1"
          />
        </div>
        <Text variant="h2">Solusi Tepat untuk Pedagang Grosir dan Eceran</Text>
        <Text variant="body1">
          Rasakan kemudahan manajemen toko dengan berbagai fitur unggulan
        </Text>
      </Box>
      <Box style={{ height: "100%" }}>
        <div className={classes.imageWrapper}>
          <Image
            src="/images/onboarding/carousel-2.png"
            width="100%"
            height="100%"
            layout="responsive"
            objectFit="contain"
            alt="image-2"
          />
        </div>
        <Text variant="h2">Kelola Usaha Jadi Lebih Mudah</Text>
        <Text variant="body1">
          Tambah produk, atur harga dan stok lebih mudah dengan basis data yang
          lengkap
        </Text>
      </Box>
      <Box style={{ height: "100%" }}>
        <div className={classes.imageWrapper}>
          <Image
            src="/images/onboarding/carousel-3.png"
            width="100%"
            height="100%"
            layout="responsive"
            objectFit="contain"
            alt="image-3"
          />
        </div>
        <Text variant="h2">Monitor Kegiatan Usaha dalam Satu Layar</Text>
        <Text variant="body1">
          Transaksi Jual Beli terhubung dengan seluruh ekosistem GudangAda
        </Text>
      </Box>
    </Carousel>
  );
};

export default AuthCarousel;
